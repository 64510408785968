import './App.scss'
import React, { useState, useEffect } from 'react'

function App () {
  const [scrolling, setScrolling] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true)

      } else {
        setScrolling(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleLinkClickToTerms = () => {
    window.open('https://fitmeal.topmusetech.com/user-agreement/#/terms-service/', '_blank')
  }

  const handleLinkClickToPrivate = () => {
    window.open('https://fitmeal.topmusetech.com/user-agreement/#/privacy-policy/', '_blank')
  }

  const handleLocateToAppStore = () => {
    window.open('', '_blank')
  }

  return (
    <div className="App">
      <header className={scrolling ? "header header-scrolled" : "header"}>
        <div className='container'>
          <div className='navL'>
            <img src="./nav_logo.png" alt="" />
          </div>
          <div className='navR'>
            <div
              onClick={handleLocateToAppStore}
              className='store'>App Store</div>
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{ backgroundColor: isHovered ? 'rgba(255,116,58,0.6)' : 'rgba(255,116,58,1.000)' }}
              className='download'>
              Download
              {isHovered && (
                <div className='qrCode'>
                  <div className='triangle'>
                    <img src="./triangle2.png" alt="" />
                  </div>
                  <div className='qrcode'>
                    <img src="./qrcode.png" alt="" />
                  </div>
                  <div className='text'>
                    <div>
                      <img src="./scan.png" alt="" />
                    </div>
                    <div>Scan to download</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      <div className='section'>
        <div className='decoration1'>
          <img src="./decoration1.png" alt="" />
        </div>
        <div className='decoration2'>
          <img src="./decoration2.png" alt="" />
        </div>
        <div className='left'>
          <img src="./left.png" alt="" />
        </div>
        <div className='right'>
          <div className='icon'>
            <img src="./icon.png" alt="" />
          </div>
          <div className='name'>FitMeal</div>
          <div className='desc'>Enter any food you crave. Our trained nutritionists will provide you with expert recipe advice to help you achieve your calorie goals while enjoying your loved treats!</div>
        </div>
      </div>
      <footer className='footer'>
        <div className='container'>
          <div className='footerL'>
            <div className='title'>FitMeal</div>
            <div className='copyright'>Copyright © 2024 TOPMUSE  PTE. LTD.</div>
          </div>
          <div className='footerR'>
            <div className='item'>
              <div className='link'
                onClick={handleLinkClickToPrivate}
              >Privacy Policy</div>
            </div>
            <div className='item'>
              <div className='link'
                onClick={handleLinkClickToTerms}
              >Terms of Use</div>
            </div>
          </div>
        </div>

      </footer>
    </div>
  )
}

export default App
